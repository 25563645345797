.PrivateUsers-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.PrivateUsers-Main-Div {
  /* background-color: #848e9d; */
  background-color: whitesmoke;
}

.PrivateUsers-DataTable {
  margin: auto;
  margin-top: 4%;
  padding-bottom: 1.5%;
  width: 80%;
  margin-left: 14%;
}

.PrivateUsers-DataTableClose {
  margin: auto;
  margin-top: 4%;
  padding-bottom: 1.5%;
  width: 90%;
  margin-left: 4%;
}

.PrivateUsers-Header-Title {
  border: 1px solid white;
  color: black;

  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}


.PrivateUsers_Sidebar {
  position: fixed;
}