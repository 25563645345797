.Corporate_homepage {
    background-color: #848e9d;
    /* background-color: whitesmoke; */
    padding-bottom: 29.3%;
}

.so-main-div-DataTable {
    margin: 20px;
    margin-top: 5%;
    width: 80vw;
    background-color: #848e9d;
    margin-left: 15%;
    border-radius: 10px;
}

.so-main-div-DataTableClose {
    margin: 20px;
    margin-top: 4%;
    width: 80vw;
    background-color: #848e9d;
    margin-left: 4%;
    border-radius: 10px;
}

.HomePage_Sidebar {
    position: fixed;
}