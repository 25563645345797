.SRU-Header-Tab {
    display: flex;
    justify-content: space-between;
}

.SRU-Main-Div {
    /* background-color: #848e9d; */
    background-color: whitesmoke;
    padding-bottom: 10%;
}

.SRU-DataTable {
    margin: auto;
    margin-top: 4.5%;
    padding-bottom: 2.5%;
    width: 85%;
    margin-left: 14%;
}

.SRU-DataTableClose {
    margin: auto;
    margin-top: 4.5%;
    padding-bottom: 2.5%;
    width: 93%;
    margin-left: 4%;
}


.SRU-Header-Title {
    border: 1px solid white;
    color: black;

    text-align: center;
    padding: 1%;
    background-color: rgb(228, 220, 226);
}

.SRU_Sidebar {
    position: fixed;
}

.SRUFilterBox_Title_div {
    margin-left: 5%
}

.SRUFilterBox {
    width: 85%;
    margin-top: 2px;
}

.SRUFilterBox_CompanyName {
    margin-bottom: 10px;
}

.SRUFilterBox_Registration {
    margin-bottom: 1px;
    margin-top: 18px;
}

.SRUFilterBox_Postalcode {
    margin-bottom: 1px;
    margin-top: 18px;
}

.SRUFilterBox_City {
    margin-bottom: 1px;
    margin-top: 18px;
}

.SRUFilterBox_createdDate {
    margin-bottom: 1px;
    margin-top: 18px;
}