.StatusBand-Main-Div {
  /* background-color: #848e9d; */
  background-color: whitesmoke;
}

.CorpStatusBand-DataTable {
  margin-right: 3%;
  margin-left: 14%;
  margin-top: 5%;
}

.CorpStatusBand-DataTableClose {
  margin-right: 3%;
  margin-left: 4%;
  margin-top: 5%;
}


.p-tabview.p-component.StatusBand-set-up-tab {
  height: 796px;
}

.bolag-count {
  margin-left: 5px;
  width: 3%;
}


.StatusBand_table1,
.StatusBand_table2 {
  /* border: 1px solid red; */
  font-weight: 800;
  color: black;
  text-align: center;
}

.corpStatusdropdown {
  width: 80%;
}

.statusBand-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.statusBand-Header-Title {
  border: 1px solid white;
  color: black;

  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}


.StatusBand_Sidebar {
  position: fixed;
}