.EmailQueue-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.EmailQueue-Main-Div {
  /* background-color: #848e9d; */
  background-color: whitesmoke;
  padding-bottom: 7%;
}

.EmailQueue-DataTable {
  margin: auto;
  margin-top: 4.5%;
  padding-bottom: 0.5%;
  width: 85%;
  margin-left: 14%;
}

.EmailQueue-DataTableClose {
  margin: auto;
  margin-top: 4.5%;
  padding-bottom: 0.5%;
  width: 95%;
  margin-left: 3%;
}

.EmailQueue-Header-Title {
  border: 1px solid white;
  color: black;

  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}

.EmailQueue_Sidebar {
  position: fixed;
}


.EmailQueue_edit_frozen {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: inherit;
  z-index: 1;
}