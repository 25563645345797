.SOLog_homepage {
  padding-bottom: 5.3%;
}

.SOLogsSideBar_sideBar {
  position: fixed;
}

.so-main-div-Log {
  margin: 20px;
  margin-top: 5%;
  width: 84vw;
  margin-left: 14%;
  border-radius: 10px;
}

.so-main-div-LogClose {
  margin: 20px;
  margin-top: 5%;
  width: 95vw;
  margin-left: 4%;
  border-radius: 10px;
}

#Log_Div {
  height: 800px;
  overflow: scroll;
  border: 1px solid rgb(136, 131, 131);
}

.scrollToBottom {
  margin-left: 90%;
  border-radius: 20px;
}

.play-sample {
  border: 1px solid black;
}



.SOLog_homepage {
  padding-bottom: 5.3%;

}

.SOLogsSideBar_sideBar {
  position: fixed;

}

.log_mainDiv {
  white-space: pre-line;
  background-color: white;
}

.so-main-div-Log {
  margin: 20px;
  margin-top: 5%;
  width: 84vw;
  margin-left: 14%;
  border-radius: 10px;
}

.so-main-div-LogClose {
  margin: 20px;
  margin-top: 5%;
  width: 95vw;
  margin-left: 3%;
  border-radius: 10px;
}

#Log_Div {
  height: 800px;
  overflow: scroll;
  border: 1px solid rgb(136, 131, 131);
}

.scrollToBottom {
  margin-left: 90%;
  border-radius: 20px;
}