.siteOwner-Main-Div {

  padding-bottom: 22%;
}

.siteOwner-LoginPage {
  padding-top: 5%;
}

.siteowner-RBL-Image {
  border: 1px solid white;
  margin: 0px 44%;
  background-color: white;
}

.navbar-logo {
  height: 50;
}

.siteOwner-Login-Div {
  border: 1px solid white;
  width: 500px;
  height: 400px;
  margin-top: 70px;

  background-color: white;
  margin-left: 37%;
}

.siteOwner-Login-Inside-Div {
  padding: 40px;
}


.siteOwner-Add-NewUser {
  margin-top: 4.5%;
  background-color: white;
  color: black;
  border: none;
  font-weight: 700;
  font-size: 12px;
}

.p-icon-field-right>.p-inputtext {
  padding-right: 8rem;
}

.siteOwner_EmailTextBox {
  width: 316px;
}

.siteOwner-EmailTextBox2 {
  width: 219px;
}