.header-Main-div {
  margin-bottom: -0.5%;
  background-color: white;
}

.header_sub_Main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.sub-menu {
  list-style: none;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.header_menu_item-has-children {
  list-style: none;
}

.header-Main-div .header_sub_Main .menu-container .menu>li {
  transition: all 0.33s ease;
  margin: 10px 10px 0px 15px;
  float: left;
  cursor: pointer;
  position: relative;
  overflow: inherit;
  margin-top: 12px;
  /* background-color: red; */
}

.header-Main-div .header_sub_Main .menu-container .menu>li.menu-item-has-children a:hover {
  margin-top: 0;
  margin-top: 0;
  background-color: #9a9ea2;
  color: white;
  border-radius: 20px;
}

.header-Main-div .header_sub_Main .menu-container .menu>li.menu-item-has-children #lang-flag-ul {
  width: 100%;
  min-width: 50px;
  padding: 7px 8px 5px 9px;
  background-color: red;
}

.header-Main-div .header_sub_Main .menu-container .menu>li:hover.menu-item-has-children .sub-menu {
  display: block;
  z-index: 100;
  background-color: red;
}

.header-Main-div .header_sub_Main .menu-container .menu>li.menu-item-has-children .sub-menu {
  box-shadow: 1px 2px 4px rgba(46, 61, 73, 0.2);
  transition: all 0.1s ease-in-out;
  -webkit-overflow-scrolling: touch;
  min-width: 200px;
  position: absolute;
  list-style: none;
  background: #fff;
  padding: 0;
  float: left;
  display: table;
  left: 0;
  width: 100%;
  float: left;
  display: none;
  border-radius: 10px;
  margin-top: 0px;
  background-color: red;
}

.corp-body {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #4e4e4e;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  left: 0;
}

.corp-body>.over-menu {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: fixed;
  height: 100%;
  content: "";
  width: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
}

.corp-body.open-menu {
  left: -250px;
}

.corp-body.open-menu>.over-menu {
  visibility: visible;
  opacity: 1;
}

.corp-body.open-menu .logo-corp {
  display: none;
  visibility: hidden;
}

.corp-body.open-menu .menu-container {
  right: 0 !important;
}

header .desk-menu .menu-container1 {
  transition: all 0.3s ease-in-out;
  background: #fff;
  position: fixed;
  height: 100%;
  width: 270px;
  right: -330px;
  top: 0;
  margin-top: 2%;
}

a {
  text-decoration: none !important;
  outline: none;
}

.hidden {
  display: none;
}

section {
  position: relative;
  width: 100%;
  float: left;
}

header {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: transparent;
  width: 100%;
  text-align: center;
  /* float: left; */
  position: fixed;
  background-color: transparent;
  z-index: 10;
  /* border: 2px solid red ; */
}

header .desk-menu .logo-adn {
  transition: all 0.3s ease-in-out;
  margin: 20px 0 0;
  margin: 21px 0px 0px 0px;
  position: relative;
  display: table;
  z-index: 1;
  margin-top: 21px;
}

header::before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform-origin: 0;
  background: #fff;
  position: absolute;
  width: 100%;
  content: "";
  top: 0;
  right: 0;
  height: 0;
}

header .desk-menu {
  position: relative;
  width: 127%;
  margin-left: -10%;
  /* float: left; */
}

header .desk-menu .logo-corp {
  position: absolute;
  top: 1%;
  float: left;
  left: -6%;
  z-index: 0;
}

header .desk-menu .logo-adn {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 20px 0 0;
  margin: 31px 0px 0px 0px;
  position: relative;
  display: table;
  z-index: 1;
}

header .desk-menu .logo-adn a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* background-image: url('https://seeklogo.com/images/G/google-logo-28FA7991AF-seeklogo.com.png'); */
  background-position: 0;
  color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 40px;
  height: 40px;
  font-size: 0;
}

header .desk-menu .box-menu {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 0px 0 0;
  display: block;
  margin: 0 auto;
  float: right;
  text-align: center;
}

header .desk-menu .menu-container {
  /* float: right; */
  z-index: 1;
  margin-left: 16%;
}

header .desk-menu .menu-container .menu-head,
header .desk-menu .menu-container .menu-foot {
  width: 100%;
  float: left;
  display: none;
}

header .desk-menu .menu-container .menu-head {
  background: #4285f4;
  padding: 16px 10px;
}

header .desk-menu .menu-container .menu-head .e1 {
  padding: 3px 0;
  float: left;
}

header .desk-menu .menu-container .menu-head .e1 img {
  width: 29px;
  float: left;
  height: 29px;
}

header .desk-menu .menu-container .menu-foot {
  position: absolute;
  bottom: 0;
  padding: 15px 0;
}

header .desk-menu .menu-container .menu-foot .social {
  display: table;
  margin: 0 auto;
}

header .desk-menu .menu-container .menu-foot .social a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  color: #fff;
  font-size: 13px;
  text-align: center;
  padding: 8px 0;
  border-radius: 50%;
  background: #797c82;
  width: 29px;
  height: 29px;
}

header .desk-menu .menu-container .menu-foot .social a:last-child {
  margin: 0;
}

header .desk-menu .menu-container .menu-foot .social a:hover {
  background: #a4a7ac;

}

header .desk-menu .menu-container .menu-foot hr {
  margin: 15px auto 20px;
  display: table;
  width: calc(100% - 20px);
}

header .desk-menu .menu-container .menu-foot address {
  position: relative;
  text-align: left;
  padding: 0 15px;
  margin: 0;
}

header .desk-menu .menu-container .menu-foot address i {
  position: absolute;
  left: 0;
  top: 0;
}

header .desk-menu .menu-container .menu-foot address span {
  padding: 0 0 0 20px;
  position: relative;
  margin-bottom: 5px;
  font-size: 12px;
  display: block;
}

header .desk-menu .menu-container .menu {
  float: left;
  padding: 0;
  margin: 0 0px 0 0;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

header .desk-menu .menu-container .menu li.back {
  display: none;
}

header .desk-menu .menu-container .menu>li {
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
  -ms-transition: all 0.33s ease;
  -o-transition: all 0.33s ease;
  transition: all 0.33s ease;
  margin: 10px 10px 0px 15px;
  float: left;
  cursor: pointer;
  position: relative;
  overflow: inherit;
  margin-top: 12px;
}

header .desk-menu .menu-container .menu>li a {
  position: relative;

  font-size: 16px;
  padding: 0px 0px;
  display: block;
  color: black;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>.corp-login>a>.login-btn-user>li>a {
  padding: 13px 10px 10px 10px;
  position: relative;
  margin-top: 13px;
  color: black;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>a {
  padding: 13px 10px 10px 10px;
  position: relative;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>span>a {
  padding: 13px 10px 10px 10px;
  position: relative;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>.corp-login>.login-btn-user {
  padding: 13px 10px 10px 10px;
  position: relative;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>a::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 6px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu {
  box-shadow: 1px 2px 4px rgba(46, 61, 73, 0.2);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  -webkit-overflow-scrolling: touch;
  min-width: 200px;
  position: absolute;
  list-style: none;
  background: #fff;
  padding: 0;
  float: left;
  display: table;
  left: 0;
  width: 100%;
  float: left;
  display: none;
  border-radius: 10px;
  margin-top: 0px;

}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li {
  width: 100%;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
  -ms-transition: all 0.33s ease;
  -o-transition: all 0.33s ease;
  transition: all 0.33s ease;
  padding: inherit;
}

.p-scrollpanel-bar.p-scrollpanel-bar-y {
  right: -96.2%;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li>a {
  color: black;
  padding: 12px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu {
  min-width: 160px;
  /* border: 1px solid red; */
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li>span>a {
  color: black;
  padding: 9px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li .sub-menu {
  display: none;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::after {
  transform: rotate(-45deg);
  right: 6px;
  top: 23px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children:hover>a {
  display: block;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children:hover .sub-menu {
  display: block;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li:hover>a {
  color: black;
  background-color: #eeeff1;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li:hover>span>a {
  /* color: #4285f4; */
  color: black;
  background-color: #eeeff1;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li:hover>a::before {
  -webkit-transform: rotate(142deg);
  -moz-transform: rotate(142deg);
  -ms-transform: rotate(142deg);
  -o-transform: rotate(142deg);
  transform: rotate(142deg);
  top: 23px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li:hover>a::after {
  -webkit-transform: rotate(42deg);
  -moz-transform: rotate(42deg);
  -ms-transform: rotate(42deg);
  -o-transform: rotate(42deg);
  transform: rotate(42deg);
  right: 11px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu .sub-menu {
  left: 100%;
  margin-top: -43px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children a {
  text-align: left;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .corp-login a:hover {
  background-color: transparent;
  color: black;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children a:hover {
  margin-top: 0;
  margin-top: 0;
  background-color: #9a9ea2;
  /* background-color: red; */
  color: white;
  border-radius: 20px;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children .corp-tryfor-free:hover {
  background-color: #c86000;

}


header .desk-menu .menu-container .menu>li.line {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  bottom: 11px;
  left: 0;
  height: 0px;
  pointer-events: none;
  /* background: #4285f4; */
  -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1);
  -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
  transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
  opacity: 0;
  display: block;
}

header .desk-menu .menu-container .menu>li:hover>a {
  /* color: #4285f4; */
  color: black;
}

header .desk-menu .menu-container .menu>li:hover>a::before {
  -webkit-transform: translateX(5px) rotate(-45deg);
  -moz-transform: translateX(5px) rotate(-45deg);
  -ms-transform: translateX(5px) rotate(-45deg);
  -o-transform: translateX(5px) rotate(-45deg);
  transform: translateX(5px) rotate(-45deg);
  width: 10px;
  right: 12px;
}

header .desk-menu .menu-container .menu>li:hover>a::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
  right: 7px;
}

header .desk-menu .menu-container .menu>li:hover.menu-item-has-children .sub-menu {
  display: block;
  z-index: 100;
}

header .hamburger-menu {
  display: none;
  z-index: 0 !important;
}

header.small::before {
  box-shadow: 0px 5px 25px 0 rgba(46, 61, 73, 0.2);
  height: 100%;
}

header.small .desk-menu .menu-container .menu>li.menu-item-has-children>a::before,
header.small .desk-menu .menu-container .menu>li.menu-item-has-children>a::after {
  background-color: #4e4e4e;
  /* background-color: red; */
}

header.small .desk-menu .logo-adn {
  margin-top: 20%;
}

header.small .desk-menu .logo-adn a {
  /* background-image: url('../img/empresa-1-logo.svg'); */
  height: 30px;
  width: 140px;
}

header.small .desk-menu .box-menu {
  padding: 0;
}

header.small .desk-menu .box-menu ul li a {
  color: black;
}

.resize-message span {
  text-align: center;
  display: block;
  color: #666;
}

.resize-message span i {
  font-size: 30px;
}

.author {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: 30px 15px 15px;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.54) 54%,
      rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0)),
      color-stop(54%, rgba(0, 0, 0, 0.54)),
      color-stop(100%, rgba(0, 0, 0, 1)));
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.54) 54%,
      rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.54) 54%,
      rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.54) 54%,
      rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.54) 54%,
      rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
}

.author a {
  font-size: 16px;
  display: table;
  margin: 0 auto;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.author a span {
  color: #4285f4;
}

button.p-button.p-component.corp-btn-tryfree {
  margin-top: 6px;
}

.logo-corp {
  margin-left: -18%;
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
  margin-top: -24px;
  width: 11%;
}

nav {
  height: 75px;
  width: 85%;
  padding: 0 16px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children #lang-flag-ul {
  width: 100%;
  min-width: 50px;
  padding: 7px 8px 5px 9px;
}


header {
  /* background-color: white; */
  background: rgb(248, 247, 247);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-ground);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: #3f72af;
  opacity: 1;
  transition: background-color 0.3s;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: var(--primary-400);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-50);
  border-bottom: 10px solid var(--surface-50);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-bar {
  background-color: var(--surface-300);
  border-radius: 0;
  opacity: 1;
  transition: background-color 0.3s;
}

header .desk-menu .menu-container .menu>li.menu-item-has-children>.corp-login>.login-btn-user {
  padding: 13px 10px 10px 10px;
  position: relative;
}

/* a.login_after_nameID {
		  margin-top: 10%;
		  margin-left: -3%;
	  } */

.Nav_LangID {
  margin-left: 10px;
}

.p-scrollpanel-content {
  margin-top: 5%;
}

.p-scrollpanel.p-component.custombar1 {
  height: 0px;
}

.cart-totalprice {
  margin-top: 3%;
}

.cart-checkout {
  margin-bottom: 6%;
  margin-top: 3%;
}

#arrow_Navbar_IncomeDeclare {
  margin-left: 22%;
}

#arrow_Navbar_Annualreport {
  margin-left: 40%;
}

@media (max-width: 1920px) {
  header .desk-menu .logo-corp {
    left: -23%;
    position: absolute;
  }

  header .desk-menu .menu-container .menu {
    margin-left: 45%;
  }

  .container {
    margin-left: 25%;
  }
}

@media (max-width: 1870px) {
  header .desk-menu .logo-corp {
    left: -20%;
    position: absolute;
  }

  .container {
    margin-left: 23%;
  }
}

@media (max-width: 1800px) {
  header .desk-menu .menu-container {
    margin-left: 13%;
  }
}

@media (max-width: 1770px) {
  header .desk-menu .logo-corp {
    left: -15%;
    position: absolute;
  }

  .container {
    margin-left: 20%;
  }
}

@media (max-width: 1700px) {
  header .desk-menu .menu-container {
    margin-left: 12%;
  }
}

@media (max-width: 1675px) {
  header .desk-menu .logo-corp {
    left: -6%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -13%;
  }

  .container {
    margin-left: 16%;
  }
}

@media (max-width: 1536px) {

  header .desk-menu .menu-container {
    margin-left: 15%;
  }

  header .desk-menu .menu-container .menu>li {
    margin: 10px 7px 0px 10px;
  }

  header .desk-menu .logo-corp {
    left: -3%;
    position: absolute;
  }

  .container {
    margin-left: 15%;
  }
}

@media (max-width: 1500px) {
  header .desk-menu .menu-container .menu>li {
    margin: 10px 7px 0px 10px;
  }

  header .desk-menu .logo-corp {
    left: -2%;
    position: absolute;
  }

  .container {
    margin-left: 13%;
  }
}

@media (max-width: 1460px) {
  header .desk-menu .menu-container {
    margin-left: 14%;
  }
}

@media (max-width: 1441px) {
  header.small .desk-menu .logo-adn {
    margin-top: 31px;
  }

  .login-btn-user {
    padding: 0px 0px 0px 1px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 25px;
  }

  .lang-link {
    position: relative;
    display: none;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li>a {
    color: #4e4e4e;
    /* color: red; */
    padding: 12px;
    width: 244px;
  }

  .cor-cards {
    display: grid;
    grid-template-columns: repeat(1, 3);
    /* -ms-grid-columns: (1fr)[3]; */
    z-index: 0;
  }

  .our-corp-form {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 0;
    overflow: hidden;
    z-index: 0;
  }

  .footer--bottom--form {
    margin-left: -8%;
    z-index: 0;
    overflow: hidden;
    position: relative;
  }

  /* 
	  .p-sidebar-right .p-sidebar {
		  width: 24rem !important;
		  height: 100%;
		  margin-top: 7.9%;
	  } */

  header .desk-menu .logo-corp {
    left: 0%;
    position: absolute;
  }

  .container {
    margin-left: 11%;
  }

  #arrow_Navbar_IncomeDeclare {
    margin-left: 20%;
  }
}

@media (min-width: 1440px) {
  .menu-item-has-children>.corp-div-image-logo>.corp-navbar-logo {
    display: none;
  }
}

@media (max-width: 1440px) {
  header .desk-menu .box-menu ul li .corp-login .login-btn-user li a {
    border-bottom: none;
  }

  header .desk-menu .box-menu ul li span a {
    border-top: 1px solid #eaeaea;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a:hover {
    margin-top: 0;
    background-color: white;
    color: white;
    border-radius: 20px;
  }

  header .desk-menu .logo-corp {
    left: 1%;
    position: absolute;
    top: -11%;
  }

  header .desk-menu {
    margin-left: -20%;
    width: 119%;
  }

  #try-for-free-id {
    border: 5px solid blue;
    display: none;
  }

  #corp-tryfree-text {
    color: white !important;
    width: 60%;
    margin-left: 3%;
    margin-top: 12px;
    /* background-color: #c86000; */
  }

  .corp-try-for-free {
    display: none;
  }

  button.p-button.p-component.user-btn {
    margin-left: 2.5%;
    margin-top: 26px;
    margin-bottom: 0px;
  }

  span.p-image.p-component.corp-navbar-logo {
    margin-left: 0%;
  }

  header {
    height: 70px;
  }

  .logo-corp {
    margin-left: -9%;
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-right: 20px;
    margin-top: -13px;
    width: 11%;
  }

  header .hamburger-menu {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: block;
    position: absolute;
    top: -2px;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 35px;
    cursor: pointer;
    right: 9%;
    z-index: 11;
    color: white;
  }

  header .hamburger-menu span {
    left: calc(-100% + -5px);
    padding: 8px 9px 8px 0;
    top: calc(50% - 18px);
    position: absolute;
    font-size: 13px;
    color: #fff;
  }

  header .hamburger-menu .bar,
  header .hamburger-menu .bar::after,
  header .hamburger-menu .bar::before {
    width: 33px;
    height: 5px;
  }

  header .hamburger-menu .bar {
    position: relative;
    -webkit-transform: translateY(25px);
    -moz-transform: translateY(25px);
    -ms-transform: translateY(25px);
    -o-transform: translateY(25px);
    transform: translateY(25px);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    background: black;
    top: -7px;
  }

  header .hamburger-menu .bar::before,
  header .hamburger-menu .bar::after {
    position: absolute;
    background: black;
    content: "";
    left: 0;
    border-radius: 5px;
  }

  header .hamburger-menu .bar::before {
    bottom: 10px;
    -webkit-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -ms-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  header .hamburger-menu .bar::after {
    top: 10px;
    -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -ms-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  header .hamburger-menu .bar.animate {
    background: rgba(255, 255, 255, 0);
  }

  header .hamburger-menu .bar.animate::after {
    top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -ms-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  header .hamburger-menu .bar.animate::before {
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -moz-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -ms-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -o-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
      transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  header .desk-menu .box-menu ul li a {
    color: #fff !important;
    border-bottom: 1px solid #eaeaea;
  }

  header .desk-menu .menu-container {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #fff;
    position: fixed;
    height: 100%;
    width: 270px;
    right: -330px;
    top: 0;
    /* margin-top: 2%; */
  }

  header .desk-menu .menu-container .menu-header-container {
    position: relative;
    float: left;
  }

  header .desk-menu .menu-container .menu-header-container ul {
    padding: 10px !important;
  }

  header .desk-menu .menu-container .menu {
    margin: 0;
  }

  header .desk-menu .menu-container .menu li.back {
    position: relative;
    display: block;
  }

  header .desk-menu .menu-container .menu li.back a {
    padding: 12px 12px 12px 35px !important;
  }

  header .desk-menu .menu-container .menu li.back a::before,
  header .desk-menu .menu-container .menu li.back a::after {
    background-color: #4e4e4e;
    position: absolute;
    content: "";
    height: 2px;
    width: 7px;
    top: 23px;
  }

  header .desk-menu .menu-container .menu li.back a::before {
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    -o-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    top: 20px !important;
    right: inherit !important;
    left: 15px !important;
  }

  header .desk-menu .menu-container .menu li.back a::after {
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    -ms-transform: rotate(45deg) !important;
    -o-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    top: 24px !important;
    left: 15px !important;
  }

  header .desk-menu .menu-container .menu li.line {
    display: none !important;
  }

  header .desk-menu .menu-container .menu>li {
    width: 100%;
    float: left;
    margin: 0;
    text-align: left;
  }

  header .desk-menu .menu-container .menu>li {
    margin: 10px 10px -10px 10px;
  }

  header .desk-menu .menu-container .menu>li a {

    padding: 12px;
    color: #4e4e4e !important;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children {
    position: initial;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a {
    padding: 12px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a::before,
  header .desk-menu .menu-container .menu>li.menu-item-has-children a::after {
    background: #4e4e4e;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a::before {
    right: 6px !important;
    top: 22px !important;
    width: 7px !important;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a::after {
    top: 26px !important;
    width: 7px !important;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu {
    box-shadow: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: block !important;
    background: #fff;
    z-index: 1;
    top: 0;
    left: 100%;
    height: 100%;
    /* border: 1px solid red; */
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu .sub-menu {
    margin-top: 0;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu.open-sub {
    left: 0%;
    background-color: white;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::before,
  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::after {
    background-color: #4e4e4e;
  }

  /* header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::before {
		  -webkit-transform: rotate(45deg) !important;
		  -moz-transform: rotate(45deg) !important;
		  -ms-transform: rotate(45deg) !important;
		  -o-transform: rotate(45deg) !important;
		  transform: rotate(45deg) !important;
		  right: 6px !important;
		  top: 22px !important;
		  width: 7px !important;
	  } */

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::after {
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    -o-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    right: 6px !important;
    top: 26px !important;
    width: 7px !important;
  }

  header .desk-menu .menu-container .menu>li:hover a:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 6px;
    top: 22px;
  }

  header .desk-menu .menu-container .menu>li:hover a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 6px;
    top: 26px;
  }

  header .desk-menu .menu-container .menu-head {
    display: block;
  }

  header .desk-menu .menu-container .menu-foot {
    display: block;
  }

  header.small .hamburger-menu span {
    color: #4e4e4e;
  }

  header.small .hamburger-menu .bar {
    background: #4e4e4e;
  }

  header.small .hamburger-menu .bar::before,
  header.small .hamburger-menu .bar::after {
    background: #4e4e4e;
  }

  header.small .hamburger-menu .bar.animate {
    background: rgba(255, 255, 255, 0);
  }

  .corp-login {
    margin-top: -31px;
  }
}

@media (max-width: 1430px) {
  header .desk-menu {
    margin-left: -20%;
    width: 118%;
  }

  header .desk-menu .menu-container .menu>li {
    margin: 10px 3px 0px 10px;
    /* border: 1px solid; */
  }
}

@media (max-width: 1386px) {
  header .desk-menu .logo-corp {
    left: -4%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -17%;
    width: 125%;
  }

  header .desk-menu .menu-container .menu>li {
    margin: 10px 3px 0px 5px;
  }
}

@media (max-width: 1350px) {
  header.small .hamburger-menu {
    right: 9%;
  }
}

@media (max-width: 1300px) {
  header .desk-menu .logo-corp {
    left: -2%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -19%;
    width: 121%;
  }
}

@media (max-width: 1280px) {
  header .desk-menu .logo-corp {
    left: -2%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -19%;
    width: 121%;
  }

  header .desk-menu .menu-container .menu>li {
    margin: 10px 1px 0px 0px;
  }

  header.small .hamburger-menu {
    right: 9%;
  }
}

@media (max-width: 1200px) {
  header .desk-menu .logo-corp {
    left: 3%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -25%;
    width: 121%;
  }
}

@media (max-width: 1024px) {
  header.small .hamburger-menu {
    right: 6%;
  }

  header .desk-menu {
    margin-left: -24%;
    width: 121%;
  }
}

@media (max-width: 991px) {
  header .desk-menu .logo-corp {
    left: -15%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -9%;
    width: 120%;
  }

  header .hamburger-menu {
    right: -7%;
  }

  .corp-login {
    margin-top: -1px;
  }
}

@media (max-width: 958px) {
  header .desk-menu .logo-corp {
    left: -11%;
    position: absolute;
  }

  header .hamburger-menu {
    right: -4%;
  }

  header .desk-menu {
    margin-left: -13%;
    width: 123%;
  }
}

@media (max-width: 950px) {
  header .desk-menu .logo-corp {
    left: -14%;
    position: absolute;
  }

  header .hamburger-menu {
    right: -4%;
  }

  header .desk-menu {
    margin-left: -9%;
    width: 118%;
  }
}

@media (max-width: 912px) {
  header .desk-menu .logo-corp {
    left: -14%;
    position: absolute;
  }

  header .hamburger-menu {
    right: -4%;
  }

  header .desk-menu {
    margin-left: -12%;
    width: 119%;
  }
}

@media (max-width: 900px) {
  header .desk-menu .logo-corp {
    left: -9%;
    position: absolute;
  }

  header .hamburger-menu {
    right: 9%;
  }

  header .desk-menu {
    margin-left: -13%;
    width: 121%;
  }
}

@media (max-width: 853px) {
  header .desk-menu .logo-corp {
    left: -3%;
    position: absolute;
  }

  header .hamburger-menu {
    right: -4%;
  }

  header .desk-menu {
    margin-left: -21%;
    width: 119%;
  }
}

@media (max-width: 834px) {
  header .desk-menu .logo-corp {
    left: -5%;
    position: absolute;
  }

  header .hamburger-menu {
    right: 9%;
  }

  header .desk-menu {
    margin-left: -16%;
    width: 121%;
  }
}

@media (max-width: 800px) {
  header .desk-menu {
    margin-left: -16%;
    width: 116%;
  }
}

@media (max-width: 768px) {
  header .desk-menu {
    margin-left: -16%;
    width: 118%;
  }

  header .desk-menu .logo-corp {
    left: -4%;
    position: absolute;
  }

  header .hamburger-menu {
    right: 12%;
  }

  header .desk-menu .menu-container .menu li a {
    padding: 8px 12px;
  }

  header .desk-menu .menu-container .menu li.back a {
    padding: 8px 12px 8px 35px !important;
  }

  header .desk-menu .menu-container .menu li.back a::before {
    top: 17px !important;
  }

  header .desk-menu .menu-container .menu li.back a::after {
    top: 21px !important;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a {
    padding: 8px 12px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a::before {
    top: 18px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children a::after {
    top: 22px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li>a {
    padding: 8px 12px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::before {
    top: 18px;
  }

  header .desk-menu .menu-container .menu>li.menu-item-has-children .sub-menu li.menu-item-has-children>a::after {
    top: 22px;
  }
}

@media (max-width: 725px) {
  header .hamburger-menu {
    right: 20%;
  }
}

@media (max-width: 754px) {
  header .desk-menu .logo-corp {
    left: -12%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -14%;
    width: 148%;
  }

  header.small .hamburger-menu {
    right: 22%;
  }
}

@media (max-width: 700px) {
  header .desk-menu {
    margin-left: -14%;
    width: 138%;
  }
}

@media (max-width: 670px) {
  header .desk-menu .logo-corp {
    left: -7%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -14%;
    width: 132%;
  }

  .p-sidebar .p-sidebar-header+.p-sidebar-content {
    padding-top: 6px;
  }
}

@media (max-width: 610px) {
  header .desk-menu .logo-corp {
    left: -3%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -20%;
    width: 126%;
  }
}

@media (max-width: 590px) {
  header .desk-menu .logo-corp {
    left: -3%;
    position: absolute;
  }

  header .desk-menu {
    margin-left: -20%;
    width: 123%;
  }
}

@media (max-width: 550px) {
  header .desk-menu {
    margin-left: -20%;
    width: 116%;
  }
}

@media (max-width: 540px) {
  .corp-navbar-logo {
    margin-left: 21%;
    width: 180px;
  }

  .corp-login {
    margin-top: 0px;
  }

  header .desk-menu {
    margin-left: -20%;
    width: 117%;
  }
}

@media (max-width: 430px) {
  header .desk-menu {
    margin-left: -23%;
    width: 122%;
  }

  header .desk-menu .logo-corp {
    left: 5%;
    top: -5px;
    position: absolute;
  }

  .logo-corp>.logo-adn>span.p-image.p-component.corp-navbar-logo {
    margin-left: 33%;
  }

  header.small .desk-menu .logo-adn {
    margin-top: 31px;
  }

  .container {
    padding: 0px;
  }

  .header.small .hamburger-menu {
    right: 13%;
  }

  header .hamburger-menu {
    top: 4px;
    right: 70px;
  }

  header .hamburger-menu .bar,
  header .hamburger-menu .bar::after,
  header .hamburger-menu .bar::before {
    width: 33px;
    height: 3px;
  }

  header .hamburger-menu .bar::before {
    bottom: 9px;
  }

  header .hamburger-menu .bar::after {
    top: 9px;
  }
}

@media (max-width: 375px) {
  .corp-navbar-logo {
    margin-left: 21%;
    width: 180px;
  }

  header .hamburger-menu {
    transition: all 0.3s ease;
    display: block;
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 51px;
    height: 42px;
    cursor: pointer;
    right: 36px;
    z-index: 11;
  }

  header .desk-menu {
    margin-left: -23%;
    width: 119%;
  }
}

@media (max-width: 320px) {
  header .desk-menu .menu-container {
    height: 100%;
    width: 79%;
  }

  header .desk-menu {
    margin-left: -23%;
    width: 123%;
  }

  header {
    height: 70px;
  }
}

@media (max-width: 280px) {
  header .hamburger-menu {
    right: 66px;
  }

  header .desk-menu {
    width: 128%;
  }

  header {
    height: 70px;
    width: 112%;
  }

  .container {
    margin-left: 34px;
  }
}

sd .scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-ground);
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: #3f72af;
  opacity: 1;
  transition: background-color 0.3s;
}

.scrollpanel-demo .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: var(--primary-400);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-50);
  border-bottom: 10px solid var(--surface-50);
}

.scrollpanel-demo .p-scrollpanel.custombar2 .p-scrollpanel-bar {
  background-color: var(--surface-300);
  border-radius: 0;
  opacity: 1;
  transition: background-color 0.3s;
}