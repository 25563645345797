.Payment-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.Payment-Main-Div {
  /* background-color: #848e9d; */
  background-color: whitesmoke;
  padding-bottom: 13%;

}

.Payment-DataTable {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 180px;
  width: 83%;
  margin-left: 14%;
}

.payment-DataTableClose {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 190px;
  width: 90%;
  margin-left: 4%;
}

.Payment-Header-Title {
  border: 1px solid white;
  color: black;

  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}


.Payment_Sidebar {
  position: fixed;
}