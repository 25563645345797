.AccountingCompany-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.AccountingCompany-Main-Div {
  /* background-color: #848e9d; */
  background-color: whitesmoke;
}

.AccountingCompany-DataTable {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 4.3%;
  width: 83%;
  margin-left: 14.5%;
}

.AccountingCompany-DataTableClose {
  margin: auto;
  margin-top: 5%;
  padding-bottom: 4.3%;
  width: 90%;
  margin-left: 4.5%;
}

.AccountingCompany-Header-Title {
  border: 1px solid white;
  color: black;
  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}

.AccountingCompany_Sidebar {
  position: fixed;
}