.AnnualReport-Header-Tab {
    display: flex;
    justify-content: space-between;
    /* font-size: 1.2rem; */
}

.AnnualReport-Main-Div {
    background-color: whitesmoke;
    padding-bottom: 10%;
}

.AnnualReport-DataTable {
    margin: auto;
    margin-top: 4.5%;
    padding-bottom: 2.5%;
    width: 85%;
    margin-left: 14%;
}

.AnnualReport-DataTableClose {
    margin: auto;
    margin-top: 4.5%;
    padding-bottom: 2.5%;
    width: 93%;
    margin-left: 4%;
}


.AnnualReport-Header-Title {
    border: 1px solid white;
    color: black;
    text-align: center;
    padding: 1%;
    background-color: rgb(228, 220, 226);
}

.AnnualReport_Sidebar {
    position: fixed;
}

.AnnualReportFilterBox_Title_div {
    margin-left: 5%
}

.AnnualReportFilterBox {
    width: 85%;
    margin-top: 2px;
}

.AnnualReportFilter_CompanyName {
    margin-bottom: 10px;
}

.AnnualReportFilter_Registration {
    margin-bottom: 1px;
    margin-top: 18px;
}

.AnnualReportFilter_Postalcode {
    margin-bottom: 1px;
    margin-top: 18px;
}

.AnnualReportFilter_City {
    margin-bottom: 1px;
    margin-top: 18px;
}

.AnnualReportFilter_createdDate {
    margin-bottom: 1px;
    margin-top: 18px;
}