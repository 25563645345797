.icon {
  font-size: 25px;
  color: red;
}

.main-container-sidebarOpenClass {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  float: right;
  overflow: hidden;
  height: 100vh;
}

.main-container-sidebarCloseClass {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  float: right;
  overflow: hidden;
  margin-top: 4em;
}

.sidebarExtent {
  background: rgb(248, 247, 247);
  color: white;
  height: 123vw;
  overflow-y: auto;
  min-height: 40vw;
  max-height: 130vw;
}

.sidebar {
  background: rgb(248, 247, 247);
  color: white;
  margin-top: 30%;

  height: 91.6vh;
  overflow: hidden;
}


.top_section {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 3%;
}

p.subtext-annual-report {
  color: white;
  margin: 0px;
  position: relative;
  margin-right: 39px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;
  background-color: rgb(249, 20, 20);
  padding: 10px;
}

.bars {
  width: 30px;
  margin-top: 11px;
}

.routes {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sidebarActive {
  display: flex;
  color: rgb(255 255 255);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
  background: #3f72af;
}

.notesLink {
  display: flex;
  color: rgb(0, 0, 0);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;
}

.menu-names-link {
  padding-top: 9px;
}

@media screen and (max-width: 960px) {
  .main-container-sidebarOpenClass {
    width: 45px;
  }

  .CIcontentParentDiv {
    margin-top: -20px;
    margin-left: -19%;
  }
}

@media screen and (max-width: 890px) {
  .main-container-sidebarOpenClass {
    width: 45px;
  }

  .sidebar {
    margin-top: -22%;
  }

  .CIcontentParentDiv {
    margin-top: -20px;
    margin-left: -24%;
  }
}

@media screen and (max-width: 850px) {
  .main-container-sidebarOpenClass {
    width: 45px;
  }

  .sidebar {
    margin-top: -23%;
  }

  .CIcontentParentDiv {
    margin-top: -20px;
    margin-left: -29%;
  }
}