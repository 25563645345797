:root {
  --ps-blue: #1b86fa;
  --ps-blue-hover: #4a9dff;
  --ps-orange: #ff5f00;
  --ps-orange-hover: #ff8233;
  --ps-red: #f44336;
}

@font-face {
  font-family: Segoe-UI;
  src: url(./Assests/Segoe\ UI.ttf);
}

#root {
  font-family: Segoe-UI;
}

body {
  font-family: Segoe-UI;
}


.statusAlterBar {
  position: fixed;
  width: 100%;
  height: 25px;
  z-index: 100;
}


.CookieConsent {
  background-color: #b1bcab !important;
  color: black !important;
  z-index: 9999 !important;
}

button#rcc-decline-button {
  display: none;
}