.templates-Header-Tab {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.templates-Header-Title {
  border: 1px solid white;
  color: black;

  text-align: center;
  padding: 1%;
  background-color: rgb(228, 220, 226);
}

.so-template-outside-div {
  background-color: whitesmoke;
  padding-bottom: 1.3%;
}

.SOTemplate_maindiv {
  margin-right: 3%;
  margin-left: 14%;
  margin-top: 4.5%;
}


.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0rem 0rem;
}

.SOTemplate_maindivClose {
  margin-right: 3%;
  margin-left: 4%;
  margin-top: 4.5%;
}

.template-file-upload {
  text-align: center;
  padding: 10px 0px;
}


.templatePageDownloadBtn {
  margin-left: 2%;
}

.template-file-upload-siteowner {
  text-align: center;
  padding: 20px 0px;
}


.proceed-btn-div>button {
  width: 13%;
  height: 40px;
  border: none;
  border-radius: 5px;
  background: #3f72af;
  color: white;
  margin-left: 85%;
  margin-top: 2%;
}

.p-fileupload-buttonbar>button {
  visibility: hidden;
}

.template-data-table-div {
  padding: 20px 0px;
}

.template-data-table {
  width: 98%;
}



.templateValidFromTitle {
  width: 8%;
}

.templateValidToTitle {
  width: 8%;
}

.templateSkMapTitle {
  width: 8%;
}

.templateAccDescTitle {
  width: 22%;
}


.Template_Sidebar {
  position: fixed;
}

.template_edit_frozen {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background: inherit;
  z-index: 1;
}

.p-datatable .p-datatable-tbody tr td,.p-datatable .p-datatable-thead > tr > th {
  border: 1px solid #dee2e6; /* Define your gridline color */
}