.so_homepage {
  background-color: #848e9d;
  /* background-color: whitesmoke; */
  padding-bottom: 3.3%;
}

.so-div {

  padding-bottom: 22%;
}

.so-main-div-DataTable {
  margin: 20px;
  margin-top: 5%;
  width: 80vw;
  background-color: #848e9d;
  margin-left: 15%;
  border-radius: 10px;
}

.so-main-div-DataTableClose {
  margin: 20px;
  margin-top: 4%;
  width: 80vw;
  background-color: #848e9d;
  margin-left: 4%;
  border-radius: 10px;
}

.so-whole-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.so-sub-div {
  background-color: white;

  text-align: center;
  margin: 50px 50px;
}

.so-sub-span {
  padding: 40px;
}

.so-chart-main-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
}

.inside-div-chart {
  margin: 50px;
  width: 30vw;
  background-color: white;
}

.HomePage_Sidebar {
  position: fixed;
}